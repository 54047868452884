@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	html {
		--color-base-gray-50: 250, 250, 250; /* #fafafa */
		--color-base-gray-100: 245, 245, 245; /* #f5f5f5 */
		--color-base-gray-200: 229, 229, 229; /* #e5e5e5 */
		--color-base-gray-300: 212, 212, 212; /* #d4d4d4 */
		--color-base-gray-400: 163, 163, 163; /* #a3a3a3 */
		--color-base-gray-500: 115, 115, 115; /* #737373 */
		--color-base-gray-600: 82, 82, 82; /* #525252 */
		--color-base-gray-700: 64, 64, 64; /* #404040 */
		--color-base-gray-800: 38, 38, 38; /* #262626 */
		--color-base-gray-900: 23, 23, 23; /* #171717 */
		--color-base-white: 253, 253, 253; /* #fdfdfd */
		--color-base-black: 5, 5, 5; /* #050505 */
		/*--color-base-brand-dark: 79, 70, 229; /* #4F46E5 */
		/*--color-base-brand-light: 55, 48, 163; /* #3730A3 */
		--color-base-brand: 55, 48, 163; /* #3730A3 */
		--color-base-brand-2: 107, 94, 255; /* #6B5EFF */

		--max-z-index: 2147483640;

		--nav-height: 100px;
		--warning-height: 80px;
	}

	.theme-dark,
	.theme-dark-gray,
	.theme-light .theme-inverted {
		--color-gray-50: var(--color-base-gray-50);
		--color-gray-100: var(--color-base-gray-100);
		--color-gray-200: var(--color-base-gray-200);
		--color-gray-300: var(--color-base-gray-300);
		--color-gray-400: var(--color-base-gray-400);
		--color-gray-500: var(--color-base-gray-500);
		--color-gray-600: var(--color-base-gray-600);
		--color-gray-700: var(--color-base-gray-700);
		--color-gray-800: var(--color-base-gray-800);
		--color-gray-900: var(--color-base-gray-900);
		--color-white: var(--color-base-white);
		--color-black: var(--color-base-black);
		--color-brand: var(--color-base-brand);
		--color-brand-2: var(--color-base-brand-2);
		--color-secondary: var(--color-base-gray-300);
		--color-muted: var(--color-base-gray-400);

		--color-toggle-handle: var(--color-gray-300);

		--color-image-empty: var(--color-gray-400);
		--color-image-loading: var(--color-gray-800);
		--color-image-loading2: var(--color-gray-700);
	}

	.theme-light,
	.theme-dark .theme-inverted {
		--color-gray-50: var(--color-base-gray-900);
		--color-gray-100: var(--color-base-gray-900);
		--color-gray-200: var(--color-base-gray-800);
		--color-gray-300: var(--color-base-gray-700);
		--color-gray-400: var(--color-base-gray-600);
		--color-gray-500: var(--color-base-gray-500);
		--color-gray-600: var(--color-base-gray-400);
		--color-gray-700: var(--color-base-gray-300);
		--color-gray-800: var(--color-base-gray-200);
		--color-gray-900: var(--color-base-gray-100);
		--color-white: var(--color-base-black);
		--color-black: var(--color-base-white);
		--color-brand: var(--color-base-brand);
		--color-brand-2: var(--color-base-brand-2);
		--color-secondary: var(--color-base-gray-700);
		--color-muted: var(--color-base-gray-400);

		--color-toggle-handle: var(--color-black);

		--color-image-empty: var(--color-gray-400);
		--color-image-loading: var(--color-gray-800);
		--color-image-loading2: var(--color-gray-700);
	}

	.theme-dark-gray {
		--color-theme-original-gray-700: var(--color-base-gray-600);
		--color-theme-original-gray-800: var(--color-base-gray-700);
		--color-theme-original-gray-900: var(--color-base-gray-700);
		--color-theme-original-black: var(--color-base-gray-800);

		--color-gray-700: var(--color-base-gray-600);
		--color-gray-800: var(--color-base-gray-700);
		--color-gray-900: var(--color-base-gray-700);
		--color-black: var(--color-base-gray-800);
	}

	html,
	body {
		@apply text-secondary hyphens-none font-sans antialiased;
	}

	body > div,
	#__next {
		@apply type-base;
	}

	body {
		--page-margin: round(calc(var(--nav-height) / 3), 1px);
	}

	a {
		@apply text-inherit no-underline;
	}

	h1,
	h2,
	h3,
	h4,
	h5 {
		@apply text-primary;
	}

	h1 {
		@apply font-semibold;
	}

	a,
	button {
		font-size: inherit;

		&:focus {
			outline: none;
			opacity: 0.97;
		}

		&:active {
			transform: translateY(1px);
		}
	}

	::-webkit-file-upload-button {
		cursor: pointer;
	}

	input[type='file'] {
		cursor: pointer;
	}

	body.grid-is-editing *,
	body.grid-is-area-selecting * {
		@apply !select-none;
		-webkit-user-drag: none;
		-khtml-user-drag: none;
		-moz-user-drag: none;
		-o-user-drag: none;
		user-drag: none;
	}

	body.grid-is-area-selecting * {
		@apply !pointer-events-none;
	}

	body.grid-is-sorting {
		cursor: grabbing;
	}

	/* Change Autocomplete styles in Chrome*/
	.input-text-login:-webkit-autofill,
	.input-text-login:-webkit-autofill:hover,
	.input-text-login:-webkit-autofill:focus {
		border-top: none;
		border-bottom: 1px solid rgba(var(--color-gray-100), 1);
		-webkit-text-fill-color: rgba(var(--color-secondary), 1);
		-webkit-box-shadow: 0 0 0px 1000px rgba(var(--color-black), 1) inset;
	}

	.input-text-default:-webkit-autofill,
	.input-text-default:-webkit-autofill:hover,
	.input-text-default:-webkit-autofill:focus {
		-webkit-text-fill-color: rgba(var(--color-black), 1);
		-webkit-box-shadow: 0 0 0px 1000px rgba(var(--color-gray-300), 1) inset;
	}

	/* Scrollbar */
	::-webkit-scrollbar-thumb {
		background: white;
	}

	::-webkit-scrollbar-track {
		-webkit-box-shadow: none;
		background: rgba(0, 0, 0, 0);
	}
}

@layer components {
	.grid-show-on-editing {
		display: none;
		pointer-events: none;
	}

	body.grid-is-editing .grid-show-on-editing {
		display: block;
	}

	body.grid-is-editing .grid-hide-on-editing {
		display: none;
	}

	.landing-column {
		opacity: 0;
	}

	.disabled {
		@apply pointer-events-none cursor-default;
	}

	.tooltip-container {
		@apply relative;
	}

	.tooltip-wrapper {
		@apply absolute left-1/2;
	}

	.animate-shimer {
		animation: shimmer 2.5s forwards infinite linear;
		background: linear-gradient(
			to right,
			rgb(var(--color-image-loading)) 0%,
			rgb(var(--color-image-loading2)) 6%,
			rgb(var(--color-image-loading)) 9%
		);
		background-size: 2000px 100%;
	}

	.transiton-filter {
		@apply duration-over ease-out;

		transition-property: filter;
	}

	.transition-backdrop {
		@apply duration-over ease-out;

		transition-property: all;
	}

	.backdrop-hide {
		backdrop-filter: blur(0px);
		background-color: rgba(0, 0, 0, 0);
	}

	.backdrop-show {
		backdrop-filter: blur(3px);
		background-color: rgba(0, 0, 0, 0.75);
	}

	.flex-center {
		@apply flex items-center justify-center;
	}

	@screen -lg {
		:root {
			--nav-height: 80px;
			--warning-height: 60px;
		}
	}

	@screen -md {
		:root {
			--nav-height: 64px;
			--warning-height: 44px;
		}
	}

	@screen -smp {
		:root {
			--nav-height: 56px;
			--warning-height: 120px;
			--page-margin: 22px;
		}
	}

	/* Typography */
	.type-huge {
		@apply -mdp:text-8xl -sm:text-6xl text-9xl;
	}

	.type-title {
		@apply -mdp:text-6xl -sm:text-5xl text-7xl;
	}

	.type-small-title {
		@apply -md:text-4xl -sm:text-4xl2 text-5xl;
	}

	.type-subtitle {
		@apply -md:text-xl text-3xl;
	}

	.type-subnav {
		@apply -md:text-xl text-2xl;
	}

	.type-pagenav {
		@apply -md:text-base text-xl;
	}

	.type-base {
		@apply -md:text-sm -sm:text-base text-base;
	}

	.type-small {
		@apply text-sm;
	}

	.type-label {
		@apply -md:text-sm text-xs;
	}

	/* Special use cases */
	.bottom-gradient {
		background: linear-gradient(
			to top,
			hsl(0, 0%, 0%) 0%,
			hsla(0, 0%, 0%, 0.738) 19%,
			hsla(0, 0%, 0%, 0.541) 34%,
			hsla(0, 0%, 0%, 0.382) 47%,
			hsla(0, 0%, 0%, 0.278) 56.5%,
			hsla(0, 0%, 0%, 0.194) 65%,
			hsla(0, 0%, 0%, 0.126) 73%,
			hsla(0, 0%, 0%, 0.075) 80.2%,
			hsla(0, 0%, 0%, 0.042) 86.1%,
			hsla(0, 0%, 0%, 0.021) 91%,
			hsla(0, 0%, 0%, 0.008) 95.2%,
			hsla(0, 0%, 0%, 0.002) 98.2%,
			hsla(0, 0%, 0%, 0) 100%
		);
	}

	.shadow-inner-1 {
		box-shadow: inset 0 0 0 1px rgba(var(--color-white), 0.1);
	}
}

@layer utilities {
	.megaphone-body p {
		@apply mb-4;
	}

	.megaphone-body a {
		@apply text-brand underline;
	}

	.megaphone-body *:last-child {
		@apply mb-0;
	}

	/* Fix bottom cut off when truncating text */
	.truncate {
		line-height: normal;
	}

	@keyframes slideIn {
		from {
			height: 0;
		}
		to {
			height: var(--warning-height);
		}
	}

	.slide-height {
		animation: slideIn 0.35s ease-in-out forwards;
	}

	.prevent-dragging {
		@apply select-none;
		user-drag: none;
		-webkit-user-drag: none;
	}

	.z-index-max {
		z-index: var(--max-z-index);
	}

	.z-index-grid-edit {
		z-index: 500;
	}

	.z-index-grid-area-select {
		z-index: 1500;
	}

	.z-index-nav {
		z-index: 1000;
	}

	.z-index-user-banner-expand {
		z-index: 999;
	}

	.z-index-user-banner-edit {
		z-index: 1001;
	}

	.z-index-user-banner-avatar {
		z-index: 1002;
	}

	.z-index-grid-settings {
		z-index: 1300;
	}

	.z-index-notifications {
		z-index: 1400;
	}

	.z-index-dropdown {
		z-index: 1700;
	}

	.z-index-nav-small {
		z-index: 1800;
	}

	.z-index-retractable-bar-bg {
		z-index: 1900;
	}

	.z-index-retractable-bar {
		z-index: 1901;
	}

	.z-index-overlay {
		z-index: 2000;
	}

	.z-index-grid-detail-info {
		z-index: 2004;
	}

	.z-index-grid-detail-comment {
		z-index: 2005;
	}

	.z-index-extension-bar {
		z-index: calc(var(--max-z-index) - 200);
	}

	.z-index-modal {
		z-index: calc(var(--max-z-index) - 100);
	}

	.no-scrollbar::-webkit-scrollbar {
		display: none;
	}
}
